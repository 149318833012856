
export default {
  props: {
    light: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    link: {
      $attrs: 'all',
      display: 'block'
    }
  },
  computed: {
    imagePath() {
      return this.light ? '/logo-light.svg' : '/logo.svg'
    }
  }
}
