import { make } from 'vuex-pathify'

const addItem = (value, item) => {
  const items = new Set(value)
  items.add(item)

  return Array.from(items)
}

const delItem = (value, item) => {
  const items = new Set(value)
  items.delete(item)

  return Array.from(items)
}

const state = () => ({
  items: [],
  primary: null
})

const getters = {
  ...make.getters(state),

  total(state) {
    return state.items.length || 0
  }
}

const mutations = {
  ...make.mutations(state),

  ADD_ITEM(state, item) {
    const items = addItem(state.items, item)

    if (items.length == 1) {
      state.primary = item
    }

    state.items = items
  },

  REMOVE_ITEM(state, item) {
    const items = delItem(state.items, item)

    if (items.length == 0) {
      state.primary = null
    } else if (items.length == 1 || item == state.primary) {
      state.primary = items[0]
    }

    state.items = items
  },

  CLEAR_ITEMS(state) {
    state.primary = null
    state.items = []
  }
}

const actions   = {
  ...make.actions(state),

  addItem({ commit }, item) {
    commit('ADD_ITEM', item)
  },

  removeItem({ commit }, item) {
    commit('REMOVE_ITEM', item)
  },

  clear({ commit }) {
    commit('CLEAR_ITEMS')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
