import Vue from 'vue'
import { scrollTo } from 'scroll-js'

Vue.config.ignoredElements = ['klarna-placement']

export default async ({ app, store }, inject) => {
  const scrollToEl = async function(target, offset) {
    const el = document.getElementById(target)

    if (el) {
      const top = el.offsetTop - (offset || 150)
      await scrollTo(window, { top: top, behavior: 'smooth' })
    }
  }

  const sale = {
    deviceImage(image) {
      return app.$assetUrl(`devices/${image}`)
    },
    sorter(a, b) {
      const order = ['*', '/', '+', '-']
      const match = (val) => {
        const matches = val.match(/\*|\/|\+|-/)
        return matches && matches[0]
      }

      const opA = match(a)
      const opB = match(b)

      return order.indexOf(opA) - order.indexOf(opB)
    },
    calculate() {
      const device = store.get('sale/device')
      const rawAnswers = store.get('sale/rawAnswers')
      const deviceQuestions = store.get('sale/deviceQuestions')

      let initial = device.initialPrice
      let finalPrice = 1
      let operations = []

      deviceQuestions.forEach(({ slug, answers }) => {
        if (answers && answers[rawAnswers[slug]]) {
          operations.push(answers[rawAnswers[slug]])
        }
      })

      operations.sort(this.sorter)

      operations.forEach((oper) => {
        initial = eval(oper)
        finalPrice = initial
      })

      if (finalPrice < 1) {
        finalPrice = device.minimumPrice || 1
      }

      store.set('sale/finalPrice', finalPrice)
    },
    async generatePDF(ctx, value) {
      const { PDFDocument } = await import('pdf-lib')
      const { default: fontkit } = await import('@pdf-lib/fontkit')

      const formUrl = '/dilosi-template.pdf'
      const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())

      const pdfDoc = await PDFDocument.load(formPdfBytes)
      const form = pdfDoc.getForm()

      const url = '/SourceSansPro.ttf'
      const fontBytes = await fetch(url).then((res) => res.arrayBuffer())

      pdfDoc.registerFontkit(fontkit)
      const font = await pdfDoc.embedFont(fontBytes)

      const data = value || store.get('sale/pdfInputs')

      Object.keys(data).forEach(key => {
        const field = form.getFieldMaybe(key)

        if (field) {
          field.setText(data[key])
        }
      })

      for (let i = 1; i < 6; i++) {
        const text = ctx.$t(`pdf.textLine${i}`, {
          vatNumber: data.vatNumber,
          taxOffice: data.taxOffice,
          serialNumber: data.serialNumber
        })

        form.getTextField(`textLine${i}`).setText(text)
      }

      const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form)

      form.updateFieldAppearances = function () {
        return rawUpdateFieldAppearances(font)
      }

      form.flatten()

      const pdfBytes = await pdfDoc.save()

      store.set('sale/pdfBlob', pdfBytes)
    },
    downloadPdf() {
      const download = require('downloadjs')
      const blob = store.get('sale/pdfBlob')

      download(blob, 'ypeuthini-dilosi-flip2store.pdf')
    },
    validPostal(code) {
      if (!code) return

      const codes = require('../static/codes').default
      return codes.includes(parseInt(code))
    }
  }

  inject('scrollTo', scrollToEl)
  inject('sale', sale)
}
