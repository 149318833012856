module.exports = {
  theme: {
    colors: {
      primary: '#448aff',
      black: '#231f20',
      success: '#00c853',
    },
    fonts: {
      body: [
        'Lato',
        'Arial',
        'sans-serif'
      ]
    },
    typography: {
      body: {
        font: 'body',
        leading: 'md',
        color: 'black',
        weight: 'normal',
        size: 'md'
      },
      title: {
        font: 'body',
        leading: 'sm',
        weight: 'semibold'
      },
      paragraph: {
        leading: 'md',
        weight: 'light'
      },
      link: {
        color: 'primary'
      }
    }
  }
}
