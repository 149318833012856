
export default {
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    search: {
      padding: 'sm',
      borderBottomWidth: 1,
      backgroundColor: 'grey-lighter'
    },
    contact: {
      padding: 'sm',
      borderTopWidth: 1,
      fontSize: 'sm',
      backgroundColor: 'grey-lighter'
    }
  },
  computed: {
    links() {
      return [
        { label: 'pages.home', route: 'index' },
        { label: 'pages.products', route: 'products' },
        { label: 'pages.accessories', route: 'accessories' },
        { label: 'pages.sale', route: 'sale' },
        { label: 'pages.repair', route: 'repair' },
        { label: 'pages.about', route: 'about' },
        { label: 'pages.contact', route: 'contact' }
      ]
    }
  }
}
