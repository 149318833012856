import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=6da1627f"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/build/repo/components/Logo.vue').default,SearchInput: require('/opt/build/repo/components/SearchInput.vue').default,UserSession: require('/opt/build/repo/components/UserSession.vue').default,ScLangSwitcher: require('/opt/build/repo/node_modules/@evince/site/lib/packages/LangSwitcher.vue').default})
