
export default {
  computed: {
    socialAccounts() {
      return [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/flip2store.gr/'
        },
        {
          name: 'Twitter',
          icon: 'twitter',
          url: 'https://twitter.com/flip2store'
        },
        {
          name: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/flip2store/'
        }
      ]
    },
    links() {
      const links = [
        { label: 'pages.cookiePolicy', route: 'cookie-policy' },
        { label: 'pages.privacyPolicy', route: 'privacy-policy' },
        { label: 'pages.terms', route: 'terms-and-conditions' },
        { label: 'pages.faq', route: 'faq' }
      ]

      return links
    }
  }
}
