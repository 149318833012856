
export default {
  data() {
    return {
      showSearch: false
    }
  },
  methods: {
    toggleSearch() {
      this.showSearch = !this.showSearch

      if (this.showSearch && this.$refs.searchInput) {
        this.$nextTick(() => {
          this.$refs.searchInput.focusInput()
        })
      }
    }
  }
}
