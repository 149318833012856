
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    links: {
      type: Array,
      default: null
    }
  },
  vessel: {
    title: {
      fontSize: 'md',
      marginBottom: '2xs'
    },
    item: {
      fontWeight: 'light'
    }
  },
  methods: {
    itemLabel(item) {
      return item.translate ? this.$t(item.label) : item.label
    }
  }
}
