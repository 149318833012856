
import { reduce } from 'vessel/utils'

const parseTaxons = (data) => {
  return reduce(data, (taxons, { name, slug }) => {
    taxons.push({
      label: name,
      translate: false,
      path: {
        name: 'categories-id',
        params: { id: slug }
      }
    })
  })
}

export default {
  async fetch() {
    const { data } = await this.$graphql.query({
      path: 'eshop/taxon',
      query: 'Taxons',
      variables: { first: 5 }
    })

    this.categories = parseTaxons(data.categories)
  },
  data() {
    return {
      categories: [],
      quickLinks: [
        {
          path: 'products',
          label: 'pages.products',
          translate: true
        },
        {
          path: 'sale',
          label: 'pages.sale',
          translate: true
        },
        {
          path: 'shopping-cart',
          label: 'pages.cart',
          translate: true
        },
        {
          path: 'repair',
          label: 'pages.repair',
          translate: true
        },
        {
          path: 'about',
          label: 'pages.about',
          translate: true
        }
      ]
    }
  }
}
