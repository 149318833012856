export default async ({ app, store }) => {
  if (app.i18n) {
    app.i18n.__switchDevicesLocale = () => {
      const saleStep = store.get('sale/step')

      if (saleStep > 1) {
        store.dispatch('sale/clearState')
      }
    }
  }
}
