export default async () => {
  const options = {"pageId":"569ccb2827b9b5d40b69a732","widgetId":"1a9anks3f","enabled":true}

  if (options.enabled !== false && typeof window !== 'undefined') {
    /* eslint-disable */
    var $_Tawk_API={},$_Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src=`https://embed.tawk.to/${options.pageId}/${options.widgetId}`;
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
    /* eslint-enable */
  }
}
