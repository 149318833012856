
export default {
  middleware: [
    'authRoute'
  ],
  provide() {
    return {
      $layout: this
    }
  },
  data() {
    return {
      alert: null,
      logo: '/logo.svg',
      image: '/flip2store-main.jpg'
    }
  },
  vessel: {
    wrapper: {
      width: 'full',
      height: 'full',
      minHeight: 'screen'
    },
    leftColumn: {
      paddingY: { all: 'md', lg: '2xl', xl: '2xl' },
      paddingX: { all: 'md', lg: '2xl', xl: '4xl' },
    },
    rightColumn: {
      borderLeftWidth: 1,
      position: 'relative',
      backgroundColor: 'white'
    },
    image: {
      height: 'full',
      position: 'absolute',
      pin: true
    }
  },
  computed: {
    formAttrs() {
      return {
        router: true,
        largeInputs: true,
        titleInHeader: false,
        titleAttrs: {
          tag: 'h1',
          fontWeight: 'bold',
          textColor: 'black',
          fontSize: { all: 'xl', sm: '2xl', lg: '3xl' },
          marginBottom: { all: 'sm', lg: 'lg' },
          marginTop: 'lg',
        }
      }
    }
  }
}
