
export default {
  head() {
    return {
      titleTemplate(title) {
        return title ? `${title} | ${this.$t('siteName')}` : this.$t('siteName')
      }
    }
  },
  vessel: {
    footer: {
      borderTopWidth: 1
    }
  },
  computed: {
    showCompare() {
      const route = this.$route.name
      return route.startsWith('products')
        || route.startsWith('categories')
        || route.startsWith('promotions')
    }
  }
}
