import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=40b6b798"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionNewsletter: require('/opt/build/repo/components/section/Newsletter.vue').default,FooterColumns: require('/opt/build/repo/components/footer/Columns.vue').default,FooterMenuBar: require('/opt/build/repo/components/footer/MenuBar.vue').default,FooterCopyright: require('/opt/build/repo/components/footer/Copyright.vue').default})
