export default [
  10431,
  10432,
  10433,
  10434,
  10435,
  10436,
  10437,
  10438,
  10439,
  10440,
  10441,
  10442,
  10443,
  10444,
  10445,
  10446,
  10447,
  10551,
  10552,
  10553,
  10554,
  10555,
  10556,
  10557,
  10558,
  10559,
  10560,
  10561,
  10562,
  10563,
  10564,
  10671,
  10672,
  10673,
  10674,
  10675,
  10676,
  10677,
  10678,
  10679,
  10680,
  10681,
  10682,
  10683,
  11141,
  11142,
  11143,
  11144,
  11145,
  11146,
  11147,
  11251,
  11252,
  11253,
  11254,
  11255,
  11256,
  11257,
  11361,
  11362,
  11363,
  11364,
  11454,
  11471,
  11472,
  11473,
  11474,
  11475,
  11476,
  11521,
  11522,
  11523,
  11524,
  11525,
  11526,
  11527,
  11528,
  11533,
  11551,
  11631,
  11632,
  11633,
  11634,
  11635,
  11636,
  11741,
  11742,
  11743,
  11744,
  11745,
  11835,
  11851,
  11852,
  11853,
  11854,
  11855,
  12131,
  12132,
  12133,
  12134,
  12135,
  12136,
  12137,
  12141,
  12241,
  12242,
  12243,
  12244,
  12351,
  12461,
  12462,
  13103,
  13121,
  13122,
  13123,
  13231,
  13341,
  13451,
  13501,
  13561,
  13562,
  13671,
  13672,
  13673,
  13674,
  13675,
  13676,
  13677,
  14121,
  14122,
  14123,
  14231,
  14232,
  14233,
  14234,
  14235,
  14341,
  14342,
  14343,
  14451,
  14452,
  14561,
  14562,
  14563,
  14564,
  14565,
  14568,
  14569,
  14572,
  14574,
  14575,
  14576,
  14578,
  14671,
  15121,
  15122,
  15123,
  15124,
  15125,
  15126,
  15127,
  15231,
  15232,
  15233,
  15234,
  15235,
  15236,
  15237,
  15238,
  15341,
  15342,
  15343,
  15344,
  15349,
  15351,
  15354,
  15451,
  15452,
  15561,
  15562,
  15669,
  15771,
  15772,
  15773,
  16121,
  16122,
  16231,
  16232,
  16233,
  16341,
  16342,
  16343,
  16344,
  16345,
  16346,
  16450,
  16451,
  16452,
  16552,
  16561,
  16562,
  16671,
  16672,
  16673,
  16674,
  16675,
  16777,
  17121,
  17122,
  17123,
  17124,
  17232,
  17234,
  17235,
  17236,
  17237,
  17341,
  17342,
  17343,
  17455,
  17456,
  17561,
  17562,
  17563,
  17564,
  17671,
  17672,
  17673,
  17674,
  17675,
  17676,
  17778,
  18010,
  18020,
  18030,
  18040,
  18050,
  18120,
  18121,
  18122,
  18233,
  18344,
  18345,
  18346,
  18450,
  18451,
  18452,
  18453,
  18454,
  18531,
  18532,
  18533,
  18534,
  18535,
  18536,
  18537,
  18538,
  18539,
  18540,
  18541,
  18542,
  18543,
  18544,
  18545,
  18546,
  18547,
  18648,
  18755,
  18756,
  18757,
  18758,
  18863,
  18900,
  18901,
  18902,
  18903,
  19001,
  19002,
  19003,
  19004,
  19005,
  19006,
  19007,
  19008,
  19009,
  19010,
  19011,
  19012,
  19013,
  19014,
  19015,
  19016,
  19100,
  19200,
  19300,
  19400,
  19500,
  19600
]
