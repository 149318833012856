import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=006fe1f8"
import script from "./MenuBar.vue?vue&type=script&lang=js"
export * from "./MenuBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ScMainNav: require('/opt/build/repo/node_modules/@evince/site/lib/packages/MainNav.vue').default,EshopCartToggle: require('/opt/build/repo/node_modules/@evince/eshop/lib/packages/CartToggle.vue').default})
