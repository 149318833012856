const loadContent = () =>
  import('./plugin.client.lazy' /* webpackChunkName: "devices/plugin.js" */)

export default (ctx, inject) => {
  let $$devices = null
  const { dbHash } = ctx.$config ? ctx.$config.devices : ctx.nuxtState.devices
  const $devices = (...dbArgs) => {
    if ($$devices) {
      return $$devices(...dbArgs)
    }
    const keys = [
      'only',
      'without',
      'sortBy',
      'limit',
      'skip',
      'where',
      'search',
      'surround'
    ]
    const mock = {}
    const toCall = []
    for (const key of keys) {
      mock[key] = (...args) => {
        toCall.push({ key, args })
        return mock
      }
    }
    mock.fetch = async () => {
      const database = await fetch(
        `https://cdn.statica.eu/assets/flip2store/content/db-${dbHash}.json`
      ).then(res => res.json())
      $$devices = (await loadContent()).default(database)
      let query = $$devices(...dbArgs)
      toCall.forEach(({ key, args }) => {
        query = query[key](...args)
      })
      return query.fetch()
    }

    return mock
  }
  inject('devices', $devices)
  ctx.$devices = $devices
}
