
export default {
  computed: {
    menuSectionAttrs() {
      return {
        items: 'center',
        width: 'full',
        grow: true,
        wrap: false
      }
    },
    links() {
      return [
        { label: 'pages.home', route: 'index' },
        { label: 'pages.products', route: 'products' },
        { label: 'pages.accessories', route: 'accessories' },
        { label: 'pages.sale', route: 'sale' },
        { label: 'pages.repair', route: 'repair' },
        { label: 'pages.about', route: 'about' },
        { label: 'pages.contact', route: 'contact' }
      ]
    }
  }
}
