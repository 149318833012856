import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f0b9936 = () => interopDefault(import('../pages/accessories.vue' /* webpackChunkName: "pages/accessories" */))
const _63b058ee = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _0ae7bf9c = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/checkout.vue' /* webpackChunkName: "pages/eshop/checkout" */))
const _69c9604b = () => interopDefault(import('../node_modules/@evince/site/lib/pages/cookie-policy.vue' /* webpackChunkName: "pages/site/cookie-policy" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _034ffb77 = () => interopDefault(import('../pages/repair.vue' /* webpackChunkName: "pages/repair" */))
const _330b853b = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/orders.vue' /* webpackChunkName: "pages/eshop/orders" */))
const _d0c3ab44 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _516c7b85 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/payment-methods.vue' /* webpackChunkName: "pages/eshop/payment-methods" */))
const _19f67bb1 = () => interopDefault(import('../pages/sale.vue' /* webpackChunkName: "pages/sale" */))
const _0a067801 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _fc71dd5e = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _bcfac6c6 = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _59e7bc12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _407be469 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/shipping-methods.vue' /* webpackChunkName: "pages/eshop/shipping-methods" */))
const _5bd9787f = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/shopping-cart.vue' /* webpackChunkName: "pages/eshop/shopping-cart" */))
const _44b764da = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _043e3a9e = () => interopDefault(import('../pages/testimonials.vue' /* webpackChunkName: "pages/testimonials" */))
const _d93727c8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0229a59b = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/terms-of-service.vue' /* webpackChunkName: "pages/eshop/terms-of-service" */))
const _d6fc104c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _824a4a7c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _c111b79e = () => interopDefault(import('../pages/categories/_id.vue' /* webpackChunkName: "pages/categories/_id" */))
const _44eda9c6 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/orders-id.vue' /* webpackChunkName: "pages/eshop/orders-id" */))
const _4849980e = () => interopDefault(import('../pages/products/_id.vue' /* webpackChunkName: "pages/products/_id" */))
const _394dbb76 = () => interopDefault(import('../pages/promotions/_id.vue' /* webpackChunkName: "pages/promotions/_id" */))
const _13972652 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/receipt.vue' /* webpackChunkName: "pages/eshop/receipt" */))
const _36854340 = () => interopDefault(import('../pages/request/_id.vue' /* webpackChunkName: "pages/request/_id" */))
const _7d1593c2 = () => interopDefault(import('../node_modules/@evince/site/lib/pages/request.vue' /* webpackChunkName: "pages/site/request" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessories",
    component: _6f0b9936,
    name: "accessories___el"
  }, {
    path: "/categories",
    component: _63b058ee,
    name: "categories___el"
  }, {
    path: "/checkout",
    component: _0ae7bf9c,
    name: "checkout___el"
  }, {
    path: "/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___el"
  }, {
    path: "/en",
    component: _0547af50,
    name: "index___en"
  }, {
    path: "/epikoinonia",
    component: _08440926,
    name: "contact___el"
  }, {
    path: "/episkevi-kai-anavathmisi-mac",
    component: _034ffb77,
    name: "repair___el"
  }, {
    path: "/orders",
    component: _330b853b,
    name: "orders___el"
  }, {
    path: "/oroi-xrisis",
    component: _d0c3ab44,
    name: "terms-and-conditions___el"
  }, {
    path: "/payment-methods",
    component: _516c7b85,
    name: "payment-methods___el"
  }, {
    path: "/polisi-metaxeirismenou-mac",
    component: _19f67bb1,
    name: "sale___el"
  }, {
    path: "/politiki-aporritou",
    component: _0a067801,
    name: "privacy-policy___el"
  }, {
    path: "/products",
    component: _fc71dd5e,
    name: "products___el"
  }, {
    path: "/promotions",
    component: _bcfac6c6,
    name: "promotions___el"
  }, {
    path: "/search",
    component: _59e7bc12,
    name: "search___el"
  }, {
    path: "/shipping-methods",
    component: _407be469,
    name: "shipping-methods___el"
  }, {
    path: "/shopping-cart",
    component: _5bd9787f,
    name: "shopping-cart___el"
  }, {
    path: "/sxetika-me-to-flip2store",
    component: _44b764da,
    name: "about___el"
  }, {
    path: "/sxolia-pelaton",
    component: _043e3a9e,
    name: "testimonials___el"
  }, {
    path: "/syxnes-erotiseis",
    component: _d93727c8,
    name: "faq___el"
  }, {
    path: "/terms-of-service",
    component: _0229a59b,
    name: "terms-of-service___el"
  }, {
    path: "/auth/login",
    component: _d6fc104c,
    name: "auth-login___el"
  }, {
    path: "/auth/register",
    component: _824a4a7c,
    name: "auth-register___el"
  }, {
    path: "/en/about-flip2store",
    component: _44b764da,
    name: "about___en"
  }, {
    path: "/en/accessories",
    component: _6f0b9936,
    name: "accessories___en"
  }, {
    path: "/en/categories",
    component: _63b058ee,
    name: "categories___en"
  }, {
    path: "/en/checkout",
    component: _0ae7bf9c,
    name: "checkout___en"
  }, {
    path: "/en/contact",
    component: _08440926,
    name: "contact___en"
  }, {
    path: "/en/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___en"
  }, {
    path: "/en/frequently-asked-questions",
    component: _d93727c8,
    name: "faq___en"
  }, {
    path: "/en/mac-repair-and-upgrade",
    component: _034ffb77,
    name: "repair___en"
  }, {
    path: "/en/orders",
    component: _330b853b,
    name: "orders___en"
  }, {
    path: "/en/payment-methods",
    component: _516c7b85,
    name: "payment-methods___en"
  }, {
    path: "/en/privacy-policy",
    component: _0a067801,
    name: "privacy-policy___en"
  }, {
    path: "/en/products",
    component: _fc71dd5e,
    name: "products___en"
  }, {
    path: "/en/promotions",
    component: _bcfac6c6,
    name: "promotions___en"
  }, {
    path: "/en/sale-of-second-hand-mac",
    component: _19f67bb1,
    name: "sale___en"
  }, {
    path: "/en/search",
    component: _59e7bc12,
    name: "search___en"
  }, {
    path: "/en/shipping-methods",
    component: _407be469,
    name: "shipping-methods___en"
  }, {
    path: "/en/shopping-cart",
    component: _5bd9787f,
    name: "shopping-cart___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _d0c3ab44,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/terms-of-service",
    component: _0229a59b,
    name: "terms-of-service___en"
  }, {
    path: "/en/testimonials",
    component: _043e3a9e,
    name: "testimonials___en"
  }, {
    path: "/en/auth/login",
    component: _d6fc104c,
    name: "auth-login___en"
  }, {
    path: "/en/auth/register",
    component: _824a4a7c,
    name: "auth-register___en"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___el"
  }, {
    path: "/en/categories/:id",
    component: _c111b79e,
    name: "categories-id___en"
  }, {
    path: "/en/orders/:id",
    component: _44eda9c6,
    name: "orders-id___en"
  }, {
    path: "/en/products/:id",
    component: _4849980e,
    name: "products-id___en"
  }, {
    path: "/en/promotions/:id",
    component: _394dbb76,
    name: "promotions-id___en"
  }, {
    path: "/en/receipt/:id",
    component: _13972652,
    name: "receipt___en"
  }, {
    path: "/en/request/:id?",
    component: _36854340,
    name: "request-id___en"
  }, {
    path: "/en/request/:id?",
    component: _7d1593c2,
    name: "request-id___en"
  }, {
    path: "/categories/:id",
    component: _c111b79e,
    name: "categories-id___el"
  }, {
    path: "/orders/:id",
    component: _44eda9c6,
    name: "orders-id___el"
  }, {
    path: "/products/:id",
    component: _4849980e,
    name: "products-id___el"
  }, {
    path: "/promotions/:id",
    component: _394dbb76,
    name: "promotions-id___el"
  }, {
    path: "/receipt/:id",
    component: _13972652,
    name: "receipt___el"
  }, {
    path: "/request/:id?",
    component: _36854340,
    name: "request-id___el"
  }, {
    path: "/request/:id?",
    component: _7d1593c2,
    name: "request-id___el"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
