export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"email":"info@flip2store.gr","address":"Ραιδεστού 29, Νίκαια, 18453","geoAddress":"Flip2store","phone":["+30 212 10 44200","+30 211 01 27 327"],"businessHours":[{"days":"Δευτέρα - Παρασκευή","hours":"10:00 - 18:00"}]},"en":{"icons":true,"email":"info@flip2store.gr","address":"Raidestou 29, 18453 Nikaia","geoAddress":"Flip2store","phone":["+30 212 10 44200","+30 211 01 27 327"],"businessHours":[{"days":"Monday - Friday","hours":"10:00 - 18:00"}]}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
