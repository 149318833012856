import { make } from 'vuex-pathify'
import { isArray } from 'vessel/utils'

export const state = () => ({
  step: 1,
  category: null,
  subcategory: null,
  device: null,
  answers: {},
  rawAnswers: {},
  userPrice: 0,
  finalPrice: 0,
  deviceQuestions: [],
  pdfInputs: {},
  pdfBlob: null
})

export const getters = make.getters(state)

export const mutations = {
  ...make.mutations(state),

  CLEAR_STATE(current, exclude) {
    const map = state()
    const excludes = isArray(exclude) ? exclude : []

    Object.keys(map).forEach((key) => {
      if (!excludes.includes(key)) {
        current[key] = map[key]
      }
    })
  }
}

export const actions = {
  ...make.actions(state),

  clearState({ commit }, exclude) {
    commit('CLEAR_STATE', exclude)
  }
}
