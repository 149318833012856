import { render, staticRenderFns } from "./UserSession.vue?vue&type=template&id=05519784"
import script from "./UserSession.vue?vue&type=script&lang=js"
export * from "./UserSession.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthSession: require('/opt/build/repo/node_modules/@evince/auth/lib/packages/AuthSession.vue').default})
