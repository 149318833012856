export const AuthSession = () => import('../../node_modules/@evince/auth/lib/packages/AuthSession.vue' /* webpackChunkName: "components/auth-session" */).then(c => wrapFunctional(c.default || c))
export const AuthSessionForm = () => import('../../node_modules/@evince/auth/lib/packages/AuthSessionForm.vue' /* webpackChunkName: "components/auth-session-form" */).then(c => wrapFunctional(c.default || c))
export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const EshopCart = () => import('../../node_modules/@evince/eshop/lib/packages/Cart.vue' /* webpackChunkName: "components/eshop-cart" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAdd = () => import('../../node_modules/@evince/eshop/lib/packages/CartAdd.vue' /* webpackChunkName: "components/eshop-cart-add" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddButton = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddButton.vue' /* webpackChunkName: "components/eshop-cart-add-button" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddForm.vue' /* webpackChunkName: "components/eshop-cart-add-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartDrawer = () => import('../../node_modules/@evince/eshop/lib/packages/CartDrawer.vue' /* webpackChunkName: "components/eshop-cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const EshopCartForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartForm.vue' /* webpackChunkName: "components/eshop-cart-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartRemove = () => import('../../node_modules/@evince/eshop/lib/packages/CartRemove.vue' /* webpackChunkName: "components/eshop-cart-remove" */).then(c => wrapFunctional(c.default || c))
export const EshopCartToggle = () => import('../../node_modules/@evince/eshop/lib/packages/CartToggle.vue' /* webpackChunkName: "components/eshop-cart-toggle" */).then(c => wrapFunctional(c.default || c))
export const EshopCompareBar = () => import('../../node_modules/@evince/eshop/lib/packages/CompareBar.vue' /* webpackChunkName: "components/eshop-compare-bar" */).then(c => wrapFunctional(c.default || c))
export const EshopCompareButton = () => import('../../node_modules/@evince/eshop/lib/packages/CompareButton.vue' /* webpackChunkName: "components/eshop-compare-button" */).then(c => wrapFunctional(c.default || c))
export const EshopSearchInput = () => import('../../node_modules/@evince/eshop/lib/packages/SearchInput.vue' /* webpackChunkName: "components/eshop-search-input" */).then(c => wrapFunctional(c.default || c))
export const PageEshopProducts = () => import('../../node_modules/@evince/eshop/lib/templates/Products.vue' /* webpackChunkName: "components/page-eshop-products" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const CarouselGallery = () => import('../../components/CarouselGallery.vue' /* webpackChunkName: "components/carousel-gallery" */).then(c => wrapFunctional(c.default || c))
export const CustomAlert = () => import('../../components/CustomAlert.vue' /* webpackChunkName: "components/custom-alert" */).then(c => wrapFunctional(c.default || c))
export const KlarnaWidget = () => import('../../components/KlarnaWidget.vue' /* webpackChunkName: "components/klarna-widget" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PhoneButton = () => import('../../components/PhoneButton.vue' /* webpackChunkName: "components/phone-button" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const Trustpilot = () => import('../../components/Trustpilot.vue' /* webpackChunkName: "components/trustpilot" */).then(c => wrapFunctional(c.default || c))
export const UserSession = () => import('../../components/UserSession.vue' /* webpackChunkName: "components/user-session" */).then(c => wrapFunctional(c.default || c))
export const AboutContent = () => import('../../components/about/Content.vue' /* webpackChunkName: "components/about-content" */).then(c => wrapFunctional(c.default || c))
export const AboutFeatures = () => import('../../components/about/Features.vue' /* webpackChunkName: "components/about-features" */).then(c => wrapFunctional(c.default || c))
export const AppointmentButton = () => import('../../components/appointment/Button.vue' /* webpackChunkName: "components/appointment-button" */).then(c => wrapFunctional(c.default || c))
export const AppointmentForm = () => import('../../components/appointment/Form.vue' /* webpackChunkName: "components/appointment-form" */).then(c => wrapFunctional(c.default || c))
export const CategoryCardHorizontal = () => import('../../components/category/CardHorizontal.vue' /* webpackChunkName: "components/category-card-horizontal" */).then(c => wrapFunctional(c.default || c))
export const CategoryCardVertical = () => import('../../components/category/CardVertical.vue' /* webpackChunkName: "components/category-card-vertical" */).then(c => wrapFunctional(c.default || c))
export const CategoryExtendedList = () => import('../../components/category/ExtendedList.vue' /* webpackChunkName: "components/category-extended-list" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/footer/Column.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/footer/Columns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const FooterCopyright = () => import('../../components/footer/Copyright.vue' /* webpackChunkName: "components/footer-copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterMenuBar = () => import('../../components/footer/MenuBar.vue' /* webpackChunkName: "components/footer-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const IconDelivery = () => import('../../components/icon/Delivery.vue' /* webpackChunkName: "components/icon-delivery" */).then(c => wrapFunctional(c.default || c))
export const LayoutBase = () => import('../../components/layout/Base.vue' /* webpackChunkName: "components/layout-base" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutHome = () => import('../../components/layout/Home.vue' /* webpackChunkName: "components/layout-home" */).then(c => wrapFunctional(c.default || c))
export const LayoutHomeHeader = () => import('../../components/layout/HomeHeader.vue' /* webpackChunkName: "components/layout-home-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutInfoBar = () => import('../../components/layout/InfoBar.vue' /* webpackChunkName: "components/layout-info-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenuBar = () => import('../../components/layout/MenuBar.vue' /* webpackChunkName: "components/layout-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutMobileHeader = () => import('../../components/layout/MobileHeader.vue' /* webpackChunkName: "components/layout-mobile-header" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributes = () => import('../../components/product/Attributes.vue' /* webpackChunkName: "components/product-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductBanner = () => import('../../components/product/Banner.vue' /* webpackChunkName: "components/product-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductBrand = () => import('../../components/product/Brand.vue' /* webpackChunkName: "components/product-brand" */).then(c => wrapFunctional(c.default || c))
export const ProductCardHorizontal = () => import('../../components/product/CardHorizontal.vue' /* webpackChunkName: "components/product-card-horizontal" */).then(c => wrapFunctional(c.default || c))
export const ProductCardLoading = () => import('../../components/product/CardLoading.vue' /* webpackChunkName: "components/product-card-loading" */).then(c => wrapFunctional(c.default || c))
export const ProductCardVertical = () => import('../../components/product/CardVertical.vue' /* webpackChunkName: "components/product-card-vertical" */).then(c => wrapFunctional(c.default || c))
export const ProductCarousel = () => import('../../components/product/Carousel.vue' /* webpackChunkName: "components/product-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductCategory = () => import('../../components/product/Category.vue' /* webpackChunkName: "components/product-category" */).then(c => wrapFunctional(c.default || c))
export const ProductCondition = () => import('../../components/product/Condition.vue' /* webpackChunkName: "components/product-condition" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/product/Details.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/product/Gallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductGrade = () => import('../../components/product/Grade.vue' /* webpackChunkName: "components/product-grade" */).then(c => wrapFunctional(c.default || c))
export const ProductInfo = () => import('../../components/product/Info.vue' /* webpackChunkName: "components/product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductPaymentOptions = () => import('../../components/product/PaymentOptions.vue' /* webpackChunkName: "components/product-payment-options" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/product/Price.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductRelated = () => import('../../components/product/Related.vue' /* webpackChunkName: "components/product-related" */).then(c => wrapFunctional(c.default || c))
export const ProductSearch = () => import('../../components/product/Search.vue' /* webpackChunkName: "components/product-search" */).then(c => wrapFunctional(c.default || c))
export const ProductSimilar = () => import('../../components/product/Similar.vue' /* webpackChunkName: "components/product-similar" */).then(c => wrapFunctional(c.default || c))
export const ProductWarranty = () => import('../../components/product/Warranty.vue' /* webpackChunkName: "components/product-warranty" */).then(c => wrapFunctional(c.default || c))
export const PromotionBanner = () => import('../../components/promotion/Banner.vue' /* webpackChunkName: "components/promotion-banner" */).then(c => wrapFunctional(c.default || c))
export const PromotionCard = () => import('../../components/promotion/Card.vue' /* webpackChunkName: "components/promotion-card" */).then(c => wrapFunctional(c.default || c))
export const PromotionExtendedCard = () => import('../../components/promotion/ExtendedCard.vue' /* webpackChunkName: "components/promotion-extended-card" */).then(c => wrapFunctional(c.default || c))
export const PromotionList = () => import('../../components/promotion/List.vue' /* webpackChunkName: "components/promotion-list" */).then(c => wrapFunctional(c.default || c))
export const PromotionProducts = () => import('../../components/promotion/Products.vue' /* webpackChunkName: "components/promotion-products" */).then(c => wrapFunctional(c.default || c))
export const QuestionAccordion = () => import('../../components/question/Accordion.vue' /* webpackChunkName: "components/question-accordion" */).then(c => wrapFunctional(c.default || c))
export const QuestionAside = () => import('../../components/question/Aside.vue' /* webpackChunkName: "components/question-aside" */).then(c => wrapFunctional(c.default || c))
export const QuestionPage = () => import('../../components/question/Page.vue' /* webpackChunkName: "components/question-page" */).then(c => wrapFunctional(c.default || c))
export const QuestionTabs = () => import('../../components/question/Tabs.vue' /* webpackChunkName: "components/question-tabs" */).then(c => wrapFunctional(c.default || c))
export const RepairFeatures = () => import('../../components/repair/Features.vue' /* webpackChunkName: "components/repair-features" */).then(c => wrapFunctional(c.default || c))
export const RepairHeader = () => import('../../components/repair/Header.vue' /* webpackChunkName: "components/repair-header" */).then(c => wrapFunctional(c.default || c))
export const SaleBanner = () => import('../../components/sale/Banner.vue' /* webpackChunkName: "components/sale-banner" */).then(c => wrapFunctional(c.default || c))
export const SaleCategories = () => import('../../components/sale/Categories.vue' /* webpackChunkName: "components/sale-categories" */).then(c => wrapFunctional(c.default || c))
export const SaleConditionInput = () => import('../../components/sale/ConditionInput.vue' /* webpackChunkName: "components/sale-condition-input" */).then(c => wrapFunctional(c.default || c))
export const SaleDeviceBar = () => import('../../components/sale/DeviceBar.vue' /* webpackChunkName: "components/sale-device-bar" */).then(c => wrapFunctional(c.default || c))
export const SaleDevices = () => import('../../components/sale/Devices.vue' /* webpackChunkName: "components/sale-devices" */).then(c => wrapFunctional(c.default || c))
export const SaleDisabled = () => import('../../components/sale/Disabled.vue' /* webpackChunkName: "components/sale-disabled" */).then(c => wrapFunctional(c.default || c))
export const SaleFormGroup = () => import('../../components/sale/FormGroup.vue' /* webpackChunkName: "components/sale-form-group" */).then(c => wrapFunctional(c.default || c))
export const SaleIntro = () => import('../../components/sale/Intro.vue' /* webpackChunkName: "components/sale-intro" */).then(c => wrapFunctional(c.default || c))
export const SalePersonalInfo = () => import('../../components/sale/PersonalInfo.vue' /* webpackChunkName: "components/sale-personal-info" */).then(c => wrapFunctional(c.default || c))
export const SalePersonalInfoForm = () => import('../../components/sale/PersonalInfoForm.vue' /* webpackChunkName: "components/sale-personal-info-form" */).then(c => wrapFunctional(c.default || c))
export const SalePriceCalculation = () => import('../../components/sale/PriceCalculation.vue' /* webpackChunkName: "components/sale-price-calculation" */).then(c => wrapFunctional(c.default || c))
export const SaleQuestions = () => import('../../components/sale/Questions.vue' /* webpackChunkName: "components/sale-questions" */).then(c => wrapFunctional(c.default || c))
export const SaleQuestionsForm = () => import('../../components/sale/QuestionsForm.vue' /* webpackChunkName: "components/sale-questions-form" */).then(c => wrapFunctional(c.default || c))
export const SaleSteps = () => import('../../components/sale/Steps.vue' /* webpackChunkName: "components/sale-steps" */).then(c => wrapFunctional(c.default || c))
export const SaleTermsInput = () => import('../../components/sale/TermsInput.vue' /* webpackChunkName: "components/sale-terms-input" */).then(c => wrapFunctional(c.default || c))
export const SectionBase = () => import('../../components/section/Base.vue' /* webpackChunkName: "components/section-base" */).then(c => wrapFunctional(c.default || c))
export const SectionCategories = () => import('../../components/section/Categories.vue' /* webpackChunkName: "components/section-categories" */).then(c => wrapFunctional(c.default || c))
export const SectionNewArrivals = () => import('../../components/section/NewArrivals.vue' /* webpackChunkName: "components/section-new-arrivals" */).then(c => wrapFunctional(c.default || c))
export const SectionNewsletter = () => import('../../components/section/Newsletter.vue' /* webpackChunkName: "components/section-newsletter" */).then(c => wrapFunctional(c.default || c))
export const SectionPromotions = () => import('../../components/section/Promotions.vue' /* webpackChunkName: "components/section-promotions" */).then(c => wrapFunctional(c.default || c))
export const SectionSale = () => import('../../components/section/Sale.vue' /* webpackChunkName: "components/section-sale" */).then(c => wrapFunctional(c.default || c))
export const SectionTestimonials = () => import('../../components/section/Testimonials.vue' /* webpackChunkName: "components/section-testimonials" */).then(c => wrapFunctional(c.default || c))
export const TestimonialCarousel = () => import('../../components/testimonial/Carousel.vue' /* webpackChunkName: "components/testimonial-carousel" */).then(c => wrapFunctional(c.default || c))
export const TestimonialList = () => import('../../components/testimonial/List.vue' /* webpackChunkName: "components/testimonial-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
