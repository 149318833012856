import { render, staticRenderFns } from "./Newsletter.vue?vue&type=template&id=6325f014"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Trustpilot: require('/opt/build/repo/components/Trustpilot.vue').default,ScNewsletterForm: require('/opt/build/repo/node_modules/@evince/site/lib/packages/NewsletterForm.vue').default})
